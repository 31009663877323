import React from 'react'
import EmagzLayout from '../../Component/Layout/EmagzLayout'
import Nav from '../../Component/Nav/Nav'

const Emagz = () => {
    return(
        <div>
            <Nav />
            <EmagzLayout />  
        </div>
        
    )
}

export default Emagz