import JuvunescentJuly from "../../Image/Emagz/JuvunescentJuly.webp"
import InspirationLatitude from "../../Image/Emagz/InspirationLatitude.webp"
import AutumnSonata from '../../Image/Emagz/AutumnSonata.webp'
import SummerMaylodies from "../../Image/Emagz/SummerMaylodies.webp"

export const HomeMagazine = [
    {
        id:1,
        title: "Summer Maylodies",
        img:SummerMaylodies,
        link:"https://heyzine.com/flip-book/a5b0ed1cb6.html"
    },
    {
        id: 2,
        title: "Juvenescent July",
        img:JuvunescentJuly,
        link:"https://heyzine.com/flip-book/c5dcd15dd7.html"
    },
    {
        id: 3,
        title: "Inspiration Latitude",
        img:InspirationLatitude,
        link: "https://heyzine.com/flip-book/48beb98269.html"
    },
    {
        id:4,
        title: "Autumn Sonata",
        img:AutumnSonata,
        link: "https://heyzine.com/flip-book/ca5d67add9.html"
    }
]
