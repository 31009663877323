import NovembersYouth from "../../Image/Emagz/NovembersYouth.webp"
import MindfulChristmas from "../../Image/Emagz/MindfulChristmas.webp"
import SquareOne from "../../Image/Emagz/SquareOne.webp"
import FestiveFebruary from "../../Image/Emagz/FestiveFebruary.webp"
import Floraison from "../../Image/Emagz/Floraison.webp"
import AphroditeApril from "../../Image/Emagz/AphroditeApril.webp"
import SummerMaylodies from "../../Image/Emagz/SummerMaylodies.webp"
import JuvunescentJuly from '../../Image/Emagz/JuvunescentJuly.webp'
import InspirationLatitude from '../../Image/Emagz/InspirationLatitude.webp'
import AutumnSonata from '../../Image/Emagz/AutumnSonata.webp'

export const magazineList = [
    {
        id:1,
        title: "November's Youth",
        img:NovembersYouth,
        link:'https://www.flipbookpdf.net/web/site/26dca515e81cc0654f5d4dda43a7d5a5ff713edc202111.pdf.html'
    },
    {
        id:2,
        title: "Mindful Christmas",
        img:MindfulChristmas,
        link:"https://heyzine.com/flip-book/056d22ec7e.html"
    },
    {
        id:3,
        title: "Square One",
        img:SquareOne,
        link:"https://heyzine.com/flip-book/6b4815b101.html"
    },
    {
        id:4,
        title: "Festive February",
        img:FestiveFebruary,
        link:"https://heyzine.com/flip-book/8f4e62c54f.html"
    },
    {
        id:5,
        title: "Floraison",
        img:Floraison,
        link:"https://heyzine.com/flip-book/dc4f4f44ec.html"
    },
    {
        id:6,
        title: "Aphrodite April",
        img:AphroditeApril,
        link:"https://heyzine.com/flip-book/5c1931dac5.html"
    },
    {
        id:7,
        title: "Summer Maylodies",
        img:SummerMaylodies,
        link:"https://heyzine.com/flip-book/a5b0ed1cb6.html"
    },
    {
        id: 8,
        title: "Juvenescent July",
        img:JuvunescentJuly,
        link:"https://heyzine.com/flip-book/c5dcd15dd7.html"
    },
    {
        id: 9,
        title: "Inspiration Latitude",
        img:InspirationLatitude,
        link: "https://heyzine.com/flip-book/48beb98269.html"
    },
    {
        id:10,
        title: "Autumn Sonata",
        img:AutumnSonata,
        link: "https://heyzine.com/flip-book/ca5d67add9.html"
    }
]
